<template>
  <div class="c--bottom_bottom site-bottom home-container-padding" v-if="!$route.query.web_view">
    <div class="container-fluid">
      <div class="airgsm-container">
        <div class="d-grid grid-cols-1 grid-cols-md-2">
          <Buttons />
          <div class="site-bottom-download-image">
            <nuxt-img
              src="/assets/images/Download-Airalo-New-One.png"
              alt="Airalo | Download mobile app"
              width="445"
              height="445"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Buttons from './store-buttons.vue'

  export default {
    components: {
      Buttons,
    },
  }
</script>

<style lang="scss" scoped>
  .site-bottom {
    background-color: white;
    .site-bottom-download-image {
      text-align: right;
      img {
        max-height: 445px;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    .site-bottom {
      .site-bottom-download-image {
        margin-bottom: 20px;
        text-align: center;
        grid-row-start: 1;
      }
    }
  }
</style>
