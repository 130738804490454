var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--bottom_store-buttons store-buttons"
  }, [_c('div', {
    staticClass: "store-buttons-content"
  }, [_c('h2', {
    staticClass: "store-buttons-text"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('components.download.apps-text')) + "\n    ")]), _vm._v(" "), _c('p', {
    staticClass: "store-buttons-desc"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('components.download.apps-description')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "store-buttons-list"
  }, [_c('ul', [_c('li', {
    on: {
      "click": function () {
        return _vm.handleStoreClick('AppStore');
      }
    }
  }, [_c('a', {
    staticClass: "application-btn appstore ico Apple-AppStore",
    attrs: {
      "target": "_blank",
      "rel": "noopener",
      "href": _vm.adjustUrlWithImpact || 'https://apps.apple.com/app/airalo-esim-store/id1475911720'
    }
  })]), _vm._v(" "), _c('li', {
    on: {
      "click": function () {
        return _vm.handleStoreClick('PlayStore');
      }
    }
  }, [_c('a', {
    staticClass: "application-btn googleplay ico GooglePlayStore",
    attrs: {
      "target": "_blank",
      "rel": "noopener",
      "href": _vm.adjustUrlWithImpact || 'https://play.google.com/store/apps/details?id=com.mobillium.airalo'
    }
  })])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }