<template>
  <div class="c--bottom_store-buttons store-buttons">
    <div class="store-buttons-content">
      <h2 class="store-buttons-text">
        {{ $t('components.download.apps-text') }}
      </h2>
      <p class="store-buttons-desc">
        {{ $t('components.download.apps-description') }}
      </p>
      <div class="store-buttons-list">
        <ul>
          <li @click="() => handleStoreClick('AppStore')">
            <a
              target="_blank"
              rel="noopener"
              :href="adjustUrlWithImpact || 'https://apps.apple.com/app/airalo-esim-store/id1475911720'"
              class="application-btn appstore ico Apple-AppStore"
            ></a>
          </li>
          <li @click="() => handleStoreClick('PlayStore')">
            <a
              target="_blank"
              rel="noopener"
              :href="adjustUrlWithImpact || 'https://play.google.com/store/apps/details?id=com.mobillium.airalo'"
              class="application-btn googleplay ico GooglePlayStore"
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import ClientSideCookies from 'js-cookie'

  export default {
    data() {
      return {
        adjustUrlWithImpact: null,
      }
    },

    computed: {
      deviceType() {
        return this.$store.getters.get_device_types
      },
    },

    methods: {
      handleStoreClick(store) {
        this.$utils.sendEvent('storeClickEvent', { app_store: store })
      },
    },

    mounted() {
      const irClickId = this.$route.query.irclickid || ClientSideCookies.get('Airalo.irclickid')

      if (!this.deviceType.isDesktop) {
        if (irClickId) {
          this.adjustUrlWithImpact = `https://app.adjust.com/87y8gpw?impactradius_click_id=${irClickId}`
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .store-buttons {
    height: 100%;

    &-content {
      display: grid;
      align-content: center;
      height: 100%;
      max-width: 445px;

      .store-buttons-text {
        color: #4a4a4a;
        font-size: 27px;
        font-weight: 600;
        letter-spacing: -0.5px;
        line-height: 32px;
        margin-bottom: 10px;
      }

      .store-buttons-desc {
        color: #8a8a8a;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 21px;
        margin-bottom: 20px;
      }

      .store-buttons-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        li {
          display: inline-block;

          & + li {
            margin-left: 20px;

            [dir='rtl'] & {
              margin-left: unset;
              margin-right: 20px;
            }
          }

          a {
            display: block;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .Apple-AppStore,
    .GooglePlayStore {
      max-width: 150px;
    }

    .store-buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      .store-buttons-content {
        max-width: unset;
        text-align: center;
        .store-buttons-list ul {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .store-buttons {
      .store-buttons-text {
        font-size: 21px !important;
      }

      .store-buttons-content {
        .store-buttons-list {
          .Apple-AppStore,
          .GooglePlayStore {
            max-width: 100%;
          }
          ul {
            justify-content: center;
            li {
              & + li {
                margin-left: 10px;

                [dir='rtl'] & {
                  margin-left: unset;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .store-buttons-content {
      padding: 0;
    }
  }
</style>
