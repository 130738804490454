var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.$route.query.web_view ? _c('div', {
    staticClass: "c--bottom_bottom site-bottom home-container-padding"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "airgsm-container"
  }, [_c('div', {
    staticClass: "d-grid grid-cols-1 grid-cols-md-2"
  }, [_c('Buttons'), _vm._v(" "), _c('div', {
    staticClass: "site-bottom-download-image"
  }, [_c('nuxt-img', {
    attrs: {
      "src": "/assets/images/Download-Airalo-New-One.png",
      "alt": "Airalo | Download mobile app",
      "width": "445",
      "height": "445",
      "loading": "lazy"
    }
  })], 1)], 1)])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }